import { reduce, isArray, merge, set } from "lodash";
import {
  FieldData,
  initObjProps,
  SaveFormProps,
} from "containers/BlockNoteRenderer/types";

export const initobj: initObjProps = {
  accessToken: "",
  assc: "",
  formId: "",
  isDevelopment: false,
  isAndroid: false,
  isIOS: false,
  uniqueFiles: [],
  identity: "",
  previewMode: false,
};

export const initForm = (
  searchParams: URLSearchParams,
  initobj: initObjProps,
) => {
  if (searchParams) {
    if (searchParams.get("accessToken")) {
      initobj["accessToken"] = "Bearer " + searchParams.get("accessToken");
    }
    if (searchParams.get("mobile")) {
      initobj["isAndroid"] = true;
    }
    if (searchParams.get("dev")) {
      initobj["isDevelopment"] = true;
    }
    if (searchParams.get("ios")) {
      initobj["isIOS"] = true;
    }
    if (searchParams.get("assc")) {
      initobj["assc"] = searchParams.get("assc");
    }
    if (searchParams.get("formId")) {
      initobj["formId"] = searchParams.get("formId");
    }
  }

  return initobj;
};

export const getUniqueName = (name: string, uniqueNames: string[]) => {
  let count = 0;
  let uniqueName = name;
  while (uniqueNames.includes(uniqueName)) {
    count++;
    uniqueName =
      count === 1
        ? `${uniqueName} 1`
        : `${uniqueName?.split(" ")?.slice(0, -1)?.join(" ")} ${count}`;
  }
  return uniqueName;
};

export const serializeData = (formData: any) => {
  let names: string[] = [];

  return reduce(
    formData,
    (acc: any[], value: any, key: string) => {
      if (value) {
        const uniqueName = getUniqueName(key, names);
        names.push(uniqueName);
        acc.push({
          label: uniqueName,
          name: uniqueName,
          value: value,
          key: value,
        });
      }
      return acc;
    },
    [],
  );
};

export const transformCheckboxData = (formData: FieldData) => {
  return reduce(
    formData,
    (acc, value, key) => {
      if (isArray(value)) {
        // Transform the array into an object
        const transformed = reduce(
          value,
          (result, { label, value }) => ({
            ...result,
            [label]: value,
          }),
          {},
        );

        // Merge transformed object directly into accumulator
        return merge(acc, transformed);
      }

      // Add non-array key-value pairs directly
      return set(acc, key, value);
    },
    {},
  );
};

export const postMessage = (payload: any, type: string) => {
  if (initobj.isIOS) {
    (window as any).webkit.messageHandlers.iosClient.postMessage(
      JSON.stringify(payload),
    );
  } else if (initobj.isAndroid) {
    if (type === "formData") {
      // @ts-ignore
      AndroidForms.formData(JSON.stringify(payload));
    } else if (type === "formFile") {
      const formFile = payload?.formFile;
      const { file, fileName, type } = formFile || {};
      // @ts-ignore
      AndroidForms.formFile(file, fileName, type);
    }
  } else {
    window.parent.postMessage(payload, "*");
  }
};

export const postFormFile = (
  res: any,
  attrkey: string,
  setFormFileData: (val: FieldData) => void,
) => {
  const { file, fileId } = res || {};
  if (!attrkey || !file) return;
  const reader = new FileReader();
  reader.addEventListener(
    "load",
    function () {
      const filePayload = {
        type: "formFile",
        formFile: {
          type: attrkey,
          file: reader?.result,
          fileName: file?.name,
        },
      };
      postMessage(filePayload, "formFile");
      setFormFileData({ [attrkey]: fileId });
    },
    false,
  );
  if (file) {
    reader.readAsDataURL(file);
  }
};

export const checkFormValidity = (
  formData: FieldData,
  formRef: any,
  isPostback: boolean,
) => {
  if (!initobj.isDevelopment) {
    let form = formRef?.current;
    if (form?.checkValidity && !form.checkValidity()) {
      const submitbutton = form.querySelector(
        'input[type="submit"]',
      ) as HTMLElement;
      submitbutton.click();
      return false;
    }
  }
  if (isPostback) {
    return postFormData(formData, formRef);
  }
  return true;
};

export const postFormData = (formData: FieldData, formRef: any) => {
  if (checkFormValidity(formData, formRef, false)) {
    const transformedData = transformCheckboxData(formData);
    const formPayload = {
      type: "formData",
      formData: serializeData(transformedData),
    };
    console.log("FormData , initobj", formPayload.formData, initobj);
    postMessage(formPayload, "formData");
  } else {
    checkFormValidity(formData, formRef, true);
  }
};

export const saveFormInstance = ({
  formData,
  id,
  formFileData,
  saveForm,
}: SaveFormProps) => {
  const serializedData = serializeData({
    ...transformCheckboxData(formData),
    ...formFileData,
  });
  const saveFormattedData =
    serializedData?.map((item: any) => ({
      key: item?.name,
      value: JSON.stringify(item?.value),
    })) || [];
  saveForm({ formFieldsData: saveFormattedData, formId: id });
};
