import React, { useCallback, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { get } from "lodash";
import { DateTime } from "luxon";
import { RootState } from "store";
import { uploadFileToS3 } from "store/services/api";
import { useUploadFileMutation } from "store/services/updateFormBuilder";
import { updateFormFileData } from "store/actions";
import { getItem } from "utils/store";
import { ROUTE_TYPE, UPLOAD_STATUS } from "utils/constant";
import picture_placeholder from "images/picture_placeholder.png";
import warning from "images/warning.png";
import Button from "components/NewButton";

type CaptureState = "start" | "capture" | "recapture";

type ButtonMapType = Record<
  CaptureState,
  { label: string; onClickFn: () => void }
>;

const PhotoCapture = ({
  required,
  attrkey,
}: {
  required?: boolean;
  attrkey?: string;
}) => {
  const dispatch = useDispatch();
  const { user_id } = getItem("user_details") || {};
  const formState = useSelector((state: RootState) => state?.form?.formState);
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [captureState, setCaptureState] = React.useState<CaptureState>("start");
  const [uploadStatus, setUploadStatus] = React.useState<string | null>(null);
  const inputRef = useRef<any>(null);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  const [uploadFile] = useUploadFileMutation();

  const onFileUploadSuccess = (fileId: number) => {
    setUploadStatus(UPLOAD_STATUS.SUCCESS);
    attrkey && dispatch(updateFormFileData({ [attrkey]: fileId }));
  };

  const onFileUploadFailure = () => {
    setUploadStatus(UPLOAD_STATUS.FAILED);
    setCaptureState("capture");
    inputRef.current.file = null;
  };

  const uploadCallback = (res: any) => {
    const { id, presigned_url } = get(res, "data.file_upload_file", {});
    const file = get(inputRef.current, "file.dataURL");

    if (!presigned_url || !file) onFileUploadFailure();
    else
      uploadFileToS3(
        presigned_url,
        file,
        id,
        onFileUploadSuccess,
        onFileUploadFailure,
      );
  };

  const onCapture = useCallback(() => {
    try {
      // create dataURL
      const canvas = canvasRef.current;
      if (!canvas || !videoRef.current) return;
      const context = canvas?.getContext("2d");
      context?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL("image/png");
      setCaptureState("recapture");

      // file upload
      setUploadStatus(UPLOAD_STATUS.UPLOADING);
      const fileName = `${DateTime.local().toFormat("yyyy-MM-dd")}.png`;
      inputRef.current.file = { dataURL, name: fileName };
      uploadFile({ fileName, fileType: "png", user_id, uploadCallback });
    } catch (e) {
      console.log("Error capturing image:", e);
    }
  }, []);

  const onRecapture = useCallback(() => {
    setCaptureState("capture");
    inputRef.current.file = null;
    setUploadStatus(null);
  }, []);

  const onStartCamera = useCallback(() => {
    const videoEl = videoRef.current;
    if (!videoEl) return;
    setIsLoading(true);
    navigator.mediaDevices
      .getUserMedia({ video: {} })
      .then((stream) => {
        videoEl.srcObject = stream;
        videoEl.play();
        setIsLoading(false);
        setCaptureState("capture");
      })
      .catch((err) => {
        if (
          err.name === "NotAllowedError" ||
          err.name === "PermissionDeniedError"
        )
          setError("permission");
        else if (
          err.name === "NotFoundError" ||
          err.name === "DevicesNotFoundError"
        )
          setError("notfound");
        else setError("unknown");
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (required && !inputRef.current?.file)
      inputRef.current?.setCustomValidity("This is a required field");
    else inputRef.current?.setCustomValidity("");
  }, [uploadStatus, required]);

  const buttonMap: ButtonMapType = {
    start: { label: "Start Camera", onClickFn: onStartCamera },
    capture: { label: "Capture Photo", onClickFn: onCapture },
    recapture: { label: "Recapture Photo", onClickFn: onRecapture },
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col justify-center items-center rounded-md p-2 bg-slate-100">
        {captureState === "start" &&
          (!error ? (
            <img src={picture_placeholder} alt="img" className="w-72 h-60" />
          ) : (
            <div className="flex flex-col justify-center items-center w-72 h-60 gap-2 text-center">
              <img src={warning} alt="warning icon" className="w-10" />
              {error !== "permission" ? (
                <>
                  <div>No functional camera detected on your system.</div>
                  <div>You need a camera to move forward.</div>
                </>
              ) : (
                <>
                  <div>You need to capture your photo.</div>
                  <div>Provide browser permissions to enable the camera.</div>
                  <a
                    target="_blank"
                    href="https://talview.freshdesk.com/support/solutions/articles/11000121624-configuring-browser-permissions-on-windows"
                    rel="noreferrer"
                    className="text-blue-400"
                  >
                    Need Help?
                  </a>
                </>
              )}
            </div>
          ))}
        <video
          ref={videoRef}
          className={classNames(
            captureState !== "capture" && "hidden",
            "w-72 h-60",
          )}
        />
        <canvas
          ref={canvasRef}
          width={288}
          height={220}
          className={classNames(captureState !== "recapture" && "hidden")}
          data-testid="canvas-element"
        ></canvas>
        {formState === ROUTE_TYPE.RENDERER && (
          <div className="flex">
            <Button
              onClick={get(buttonMap, `${captureState}.onClickFn`)}
              loading={isLoading || uploadStatus === UPLOAD_STATUS.UPLOADING}
              className="mt-2"
            >
              {get(buttonMap, `${captureState}.label`)}
            </Button>
            <input
              ref={inputRef}
              className="opacity-0 w-0.5 pointer-events-none"
            />
          </div>
        )}
        {uploadStatus === UPLOAD_STATUS.FAILED && (
          <div className="text-red-500 mt-2 text-sm">
            Something went wrong. Please try again.
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoCapture;
