import React, { useEffect, useRef, useState, useMemo, memo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { map, cloneDeep, isEqual, get } from "lodash";
import classNames from "classnames";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCreateBlockNote } from "@blocknote/react";
import "@blocknote/core/fonts/inter.css";
import {
  useFetchCandidateDetailsQuery,
  useGetFormStructureQuery,
  useSaveFormInstanceMutation,
  useGetFormInstanceQuery,
} from "store/services/updateFormBuilder";
import {
  updateFormData,
  updateFormFileData,
  updateFormState,
} from "store/actions";
import { RootState } from "store";
import { Data } from "Pages/Editor";
import { getItem } from "utils/store";
import { DEVICE_TYPE, FORM_TYPE, ROUTE_TYPE } from "utils/constant";
import {
  initForm,
  initobj,
  postFormData,
  saveFormInstance,
} from "containers/BlockNoteRenderer/utils/formDataHelpers";
import {
  initBlocksRender,
  renderContent,
} from "containers/BlockNoteRenderer/utils/renderHelpers";
import Loader from "components/Loader";
import Toggle from "components/Popover/MenuPopover/ToggleSwitch";
import { schema } from "components/BlockNoteEditor";
import {
  DeviceType,
  FieldData,
  FormRendererProps,
  LogicProps,
  CandidateData,
} from "./types";
import { trackEvent } from "utils/mixpanel/mixpanelActions";
import { mixpanelEvents } from "utils/mixpanel/mixpanelEvent";

export const loadSavedContent = (
  content: Array<Record<string, any>>,
  form_instance_data: FieldData,
  candidateDetails: FieldData,
) => {
  try {
    return map(cloneDeep(content), (block) => {
      const attrKey = block?.props?.attrkey;
      if(block.type === 'checkbox'){
          const filtered_checkbox = block.props.options?.filter((obj: {id: any, label: string, name: string, value: any, form_instance_filled: any}) => {
            let checkbox_obj = { value: null };
            if(form_instance_data?.[obj.label] === obj.value){
              checkbox_obj = obj;
              if(block.props.label){
                block.props.form_instance_filled = true
              } else {
                obj.form_instance_filled = block.props.label;
              }
            } else if(candidateDetails?.[obj.label] === obj.value){
              checkbox_obj = obj
            }
            return checkbox_obj.value
          });
          if(get(filtered_checkbox,'0.value')) block.props.value  = filtered_checkbox;
      } else {
        if(attrKey && form_instance_data && form_instance_data[attrKey]){
          block.props.value = form_instance_data[attrKey];
          block.props.form_instance_filled = true;
        } else if (attrKey && candidateDetails && candidateDetails[attrKey]){
          block.props.value = candidateDetails[attrKey];
        }
      }
      return block;
    });
  } catch (error) {
    console.log("load saved content error", error);
    return content;
  }
};

export const getFormDataFromContent = (savedContent: Array<Record<string, any>>) => {
  try {
    const obj: Record<string, string> = {};
    savedContent.forEach((data)=>{
      if(data?.props?.attrkey){
        const fieldLabel = get(data, "props.attrkey");
        const fieldValue = get(data, "props.value");
        if(fieldLabel && fieldValue && !Array.isArray(fieldValue)){
          obj[fieldLabel] = fieldValue;
        } else if(fieldValue && Array.isArray(fieldValue)) {
          fieldValue.map((i)=>{
            if(i.value && i.label){
              obj[i.label] = i.value
            }
            return i;
          })
        }
      }
    })
    return obj;
  } catch (error) {
    console.log("Error in getting Form Data", error);
    return {};
  }
};

const BlockNoteRenderer: React.FC<FormRendererProps> = ({
  setFormState,
  formType,
  formContent,
}) => {
  const { id } = useParams() || {};
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { formData, formFileData } =
    useSelector((state: RootState) => state?.form) || {};
  const editor = useCreateBlockNote({ schema });

  const [deviceType, setDeviceType] = useState<DeviceType>(DEVICE_TYPE.DESKTOP);
  const [groupedContent, setGroupedContent] = useState<any>([]);
  const logicsRef = useRef<LogicProps[]>([]);
  const formRef = useRef<HTMLFormElement>(null);

  const [saveForm] = useSaveFormInstanceMutation();
  const { data, status } = useGetFormStructureQuery(
    {
      id,
      state: ROUTE_TYPE.RENDERER,
    },
    { skip: !!formType },
  );
  const { type, structure, form_version_id } = (data || {}) as Data;
  const { data: candidateData } = useFetchCandidateDetailsQuery<{
    data: CandidateData | undefined;
  }>(null, {
    skip: type !== FORM_TYPE.REGISTRATION,
  });

  const { data: form_instance_data } = useGetFormInstanceQuery<{
    data: FieldData;
  }>({
    form_version_id,
  }, {
    skip: !form_version_id
  });

  const formTypeValue = type || formType;
  const content = structure?.doc || formContent?.doc || [{}];
  const btnProps = structure?.button?.props ||
    formContent?.button?.props || { label: "" };

  const { user_id } = getItem("user_details") || {};
  const savedKey = `frm_save_${user_id}_${id}`;
  // const localSaved = getItem(savedKey) || {};

  const setFormData = (fieldValues: FieldData) =>
    dispatch(updateFormData({ savedKey, fieldValues }));

  const setFormFileData = (fieldValues: FieldData) =>
    dispatch(updateFormFileData(fieldValues));

  useEffect(() => {
    dispatch(updateFormState(ROUTE_TYPE.RENDERER));
    initForm(searchParams, initobj);
    const eventMethod = "addEventListener";
    const eventer = window[eventMethod];
    const messageEvent = "message";

    eventer(messageEvent, (event) => {
      //Trigger formSubmit from parent (For Recruit)
      if (event.data.type === "submitEvaluationForm") {
        document.getElementById("submitButton")?.click();
      }
    });
    trackEvent(mixpanelEvents.FORM_LANDED);
  }, []);

  useEffect(() => {
    if (!isEqual(content, [{}])) {
      const candidateDetails = get(candidateData, "can_candidate.0", {});
      const savedContent = loadSavedContent(content, form_instance_data, candidateDetails);
      const filteredFormData = getFormDataFromContent(savedContent);
      setFormData(filteredFormData);
      (async () => {
        const { groupedBlocks, conditionalLogics } = await initBlocksRender(
          savedContent,
          setFormFileData,
          setFormData,
          editor,
          logicsRef,
        );
        setGroupedContent(groupedBlocks);
        logicsRef.current = conditionalLogics;
      })();
    }
  }, [content, candidateData, form_instance_data]);

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    postFormData(formData, formRef);
    saveFormInstance({
      formData,
      id: Number(id),
      formFileData,
      saveForm,
    });
    trackEvent(mixpanelEvents.FORM_SUBMIT);
  };

  const grids = useMemo(
    () =>
      map(groupedContent, ({ htmlBlocks, layout, props }) => (
        <div
          className={classNames(
            props?.border && "border border-solid mb-4 p-4 rounded-md",
          )}
        >
          {renderContent(htmlBlocks, layout, deviceType)}
        </div>
      )),
    [groupedContent, deviceType],
  );

  return (
    <div className="max-h-screen w-full min-h-screen bg-white block-render">
      {formType && (
        <div className="cursor-pointer w-full flex justify-end p-5 bg-white">
          <div className="mr-3">
            <Toggle
              labelClassNames="text-gray-500"
              label={"Mobile View"}
              onChange={(checked: boolean) => {
                if (checked) setDeviceType(DEVICE_TYPE.MOBILE);
                else setDeviceType(DEVICE_TYPE.DESKTOP);
              }}
              size="sm"
              checked={deviceType === DEVICE_TYPE.MOBILE}
            />
          </div>
          <div
            role="button"
            data-testid="back-to-editor"
            onClick={() => setFormState?.(ROUTE_TYPE.BUILDER)}
            className="text-sm font-light	text-gray-500	"
            title="Back to Editor"
          >
            <FontAwesomeIcon icon={solid("right-from-line")} />
            Back to Editor
          </div>
        </div>
      )}
      <div
        className={classNames(
          "mx-auto mt-2 px-5 py-10 bg-white overflow-auto",
          formType
            ? "h-[85vh] max-h-[85vh] " +
                (deviceType === DEVICE_TYPE.MOBILE
                  ? "max-w-sm shadow-lg"
                  : "max-w-6xl")
            : "max-h-screen h-screen",
        )}
      >
        <form
          ref={formRef}
          onSubmit={onFormSubmit}
          id={
            formTypeValue === FORM_TYPE.REGISTRATION
              ? "assessmentForm"
              : "evaluationForm"
          }
        >
          {status === "pending" ? (
            <div className="h-full flex items-center justify-center grow">
              <Loader />
            </div>
          ) : (
            <div className="bn-container">{grids}</div>
          )}
          <div className="w-100 flex justify-center mt-4">
            <button
              id="submitButton"
              className={`border rounded py-2 px-3 bg-blue-700 ${
                formTypeValue !== FORM_TYPE.REGISTRATION && "hidden"
              } text-white text-sm font-medium text-center`}
              type="submit"
            >
              {btnProps?.label || "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default memo(BlockNoteRenderer);
