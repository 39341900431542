import { createAction } from "@reduxjs/toolkit";
import { FieldData } from "containers/BlockNoteRenderer/types";
import { FormStateType } from "Pages/Editor";
import { UpdateFormDataPayload } from "store/types";
import {
  UPDATE_FORM_DATA,
  UPDATE_FORM_FILE_DATA,
  UPDATE_FORM_STATE,
} from "store/constants";

export const updateFormData =
  createAction<UpdateFormDataPayload>(UPDATE_FORM_DATA);

export const updateFormFileData = createAction<FieldData>(
  UPDATE_FORM_FILE_DATA,
);

export const updateFormState = createAction<FormStateType>(UPDATE_FORM_STATE);
