import { isDuplicate } from "../../utils";
import { TextOptions } from "../Tools/Text";
import { DateTime } from "luxon";

export const valueFields = ["minLength", "maxLength", "value", "min", "max"];
export const valueNames = [
  "Minimum Length",
  "Maximum Length",
  "Default Value",
  "Minimum Value",
  "Maximum Value",
];
export const fromDateString = (date: string) =>
  DateTime.fromFormat(date, "d MMMM yyyy").valueOf();

export const BytesPerMB = 1048576;
export const FileOptions = [
  {
    id: "pdf",
    name: "PDF",
    label: ".pdf",
    value: "pdf",
    ext: ["pdf", "application/pdf"],
  },
  {
    id: "doc",
    name: "DOC",
    label: ".doc",
    value: "doc",
    ext: ["doc"],
  },
  {
    id: "docx",
    name: "DOCX",
    label: ".docx",
    value: "docx",
    ext: [
      "docx",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
  },
  {
    id: "txt",
    name: "TXT",
    label: ".txt",
    value: "txt",
    ext: ["txt"],
  },
  {
    id: "jpg",
    name: "JPG",
    label: ".jpg",
    value: "jpg",
    ext: ["jpg", "image/jpg"],
  },
  {
    id: "jpeg",
    name: "JPEG",
    label: ".jpeg",
    value: "jpeg",
    ext: ["jpeg", "image/jpeg"],
  },
  {
    id: "png",
    name: "PNG",
    label: ".png",
    value: "png",
    ext: ["png", "image/png"],
  },
  {
    id: "gif",
    name: "GIF",
    label: ".gif",
    value: "gif",
    ext: ["gif", "image/gif"],
  },
  {
    id: "default",
    name: "Default",
    label: ".*",
    value: "default",
    ext: ["*"],
  },
];

export const layoutOptions = [
  {
    label: "1 column",
    value: 1,
  },
  {
    label: "2 columns",
    value: 2,
  },
  {
    label: "3 columns",
    value: 3,
  },
];

export const EMAILREGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export enum errorType {
  "invalidMinLength" = "Invalid minimum length",
  "invalidMaxLength" = "Invalid maximum length",
  "invalidMinValue" = "Invalid minimum value",
  "invalidMaxValue" = "Invalid maximum value",
  "emptyName" = "Name cannot be empty",
  "minGreaterThanMax" = "Minimum value should be less than the maximum value",
  "defaultLongerThanMax" = "Default value should be shorter than the maximum length",
  "defaultShorterThanMin" = "Default value should be longer than the minimum length",
  "defaultGreaterThanMax" = "Default value should be less than the maximum value",
  "defaultLessThanMin" = "Default value should be greater than the minimum value",
  "invalidNumber" = "Invalid number",
  "invalidEmail" = "Invalid email",
  "invalidList" = "Duplicate values or labels",
  "invalidDate" = "Invalid Date",
  "labelSize" = "Label should be less than 20 characters",
}
export const blockMenuList: SingleBlockType[] = [
  {
    id: 1,
    name: "InputText",
    type: "input",
    properties: {
      required: false,
      placeholder: "Enter Input value",
    },
  },
  {
    id: 2,
    name: "Number",
    type: "number",
    properties: {
      required: false,
    },
  },
  {
    id: 2,
    name: "Email",
    type: "email",
    properties: {
      required: false,
      placeholder: "example@domain.com",
    },
  },
  {
    id: 3,
    name: "Checkbox",
    type: "checkbox",
    properties: {
      options: [
        {
          id: "option1",
          name: "Option1",
          label: "Option 1",
          value: "option1",
        },
      ],
    },
  },
  {
    id: 4,
    name: "Dropdown",
    type: "select",
    properties: {
      options: [
        {
          id: "option1",
          name: "Option1",
          label: "Option 1",
          value: "option1",
        },
      ],
    },
  },
  {
    id: 5,
    name: "Radio Button Group",
    type: "radio",
    properties: {
      options: [
        {
          id: "option1",
          name: "Option1",
          label: "Option 1",
          value: "option1",
        },
      ],
    },
  },
  {
    id: 6,
    name: "Text",
    type: "text",
    properties: {
      label: "Add your label",
      placeholder: "Enter your text",
    },
  },
  {
    id: 7,
    name: "Date Picker",
    type: "datepicker",
    properties: {},
  },
  {
    id: 8,
    name: "Text Area",
    type: "textarea",
    properties: {
      value: "",
      placeholder: "Add your Text Content",
    },
  },
  {
    id: 9,
    name: "File",
    type: "file",
    properties: {},
  },
];

export const validationBox: any = {
  input: {
    attrkey: (value: string) => value?.length > 0,
    minLength: (value: string) => !isNaN(+value) && +value > 0,
    maxLength: (value: string) => !isNaN(+value) && +value > 0,
  },
  number: {
    attrkey: (value: string) => value?.length > 0,
    value: (value: string) => !isNaN(+value),
    min: (value: string) => !isNaN(+value),
    max: (value: string) => !isNaN(+value),
  },
  email: {
    attrkey: (value: string) => value?.length > 0,
    value: (value: string) =>
      !!String(value).toLowerCase().trim().match(EMAILREGEX),
  },
  checkbox: {
    attrkey: (value: string) => value?.length > 0,
    options: (arr: any[]) => isDuplicate(arr),
  },
  select: {
    attrkey: (value: string) => value?.length > 0,
    options: (arr: any[]) => isDuplicate(arr),
  },
  radio: {
    attrkey: (value: string) => value?.length > 0,
    options: (arr: any[]) => isDuplicate(arr),
  },
  textarea: {
    attrkey: (value: string) => value?.length > 0,
    minLength: (value: string) => !isNaN(+value) && +value > 0,
    maxLength: (value: string) => !isNaN(+value) && +value > 0,
  },
  text: {
    attrkey: (value: string) => value?.length > 0,
  },
  datepicker: {
    attrkey: (value: string) => value?.length > 0,
  },
  file: {
    attrkey: (value: string) => value?.length > 0,
    size: (value: string) => !isNaN(+value) && +value > 0,
    fileTypes: (arr: any[]) => isDuplicate(arr),
  },
  image: {},
  photoCapture: { attrkey: (value: string) => value?.length > 0 },
  sectionStart: {},
  button: {
    label: (value: string) => value?.length < 21,
  },
};

const nameField = {
  name: "Name*",
  key: "attrkey",
  type: "input",
  error: errorType.emptyName,
};

const labelField = {
  name: "Label",
  key: "label",
  type: "input",
};

const requiredField = {
  name: "Required",
  key: "required",
  type: "checkbox",
};

const disableField = {
  name: "Disable Field",
  key: "disabled",
  type: "checkbox",
};

const tooltipField = {
  name: "Tooltip",
  key: "tooltip",
  type: "input",
  hasToggle: true,
};

const placeholderField = {
  name: "Placeholder",
  key: "placeholder",
  type: "input",
  hasToggle: true,
};

const layoutField = {
  name: "Select Layout",
  key: "layout",
  type: "select",
  value: layoutOptions[0],
  options: layoutOptions,
};

const updatableField = {
  name: "Updatable Field",
  key: "updatable",
  type: "checkbox",
}

export const propertyBox: any = {
  input: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: "Default Value",
      key: "value",
      type: "input",
      hasToggle: true,
    },
    {
      name: "Minimum Length",
      key: "minLength",
      type: "input",
      error: errorType.invalidMinLength,
      hasToggle: true,
      subType: "number",
    },
    {
      name: "Maximum Length",
      key: "maxLength",
      type: "input",
      error: errorType.invalidMaxLength,
      hasToggle: true,
      subType: "number",
    },
    {
      name: "Regex",
      key: "pattern",
      type: "input",
      hasToggle: true,
    },
    layoutField,
  ],
  number: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: "Default Value",
      key: "value",
      type: "input",
      error: errorType.invalidNumber,
      hasToggle: true,
      subType: "number",
    },
    {
      name: "Minimum Value",
      key: "min",
      type: "input",
      error: errorType.invalidMinValue,
      hasToggle: true,
      subType: "number",
    },
    {
      name: "Maximum Value",
      key: "max",
      type: "input",
      error: errorType.invalidMaxValue,
      hasToggle: true,
      subType: "number",
    },
    layoutField,
  ],
  email: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: "Default Value",
      key: "value",
      type: "input",
      error: errorType.invalidEmail,
      hasToggle: true,
    },
    layoutField,
  ],
  checkbox: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    {
      name: "Checkboxes",
      key: "options",
      type: "inputList",
      error: errorType.invalidList,
    },
    layoutField,
  ],
  select: [
    nameField,
    labelField,
    requiredField,
    disableField,
    tooltipField,
    {
      name: "Default Value",
      key: "value",
      type: "select",
      hasToggle: true,
    },
    {
      name: "Select Field type",
      key: "selectFieldType",
      type: "select",
      hasToggle: true,
    },
    {
      name: "Select Parent Field",
      key: "selectParentField",
      type: "select",
      hasToggle: true,
    },
    {
      name: "Options",
      key: "options",
      type: "inputList",
      error: errorType.invalidList,
      value: [
        {
          id: "option1",
          name: "Option1",
          label: "Option 1",
          value: "option1",
        },
      ],
    },
    layoutField,
  ],
  radio: [
    nameField,
    labelField,
    requiredField,
    disableField,
    tooltipField,
    {
      name: "Default Value",
      key: "value",
      type: "select",
      hasToggle: true,
    },
    {
      name: "Align Options Horizontal",
      key: "rowAlign",
      type: "checkbox",
    },
    {
      name: "Options",
      key: "options",
      type: "inputList",
      error: errorType.invalidList,
      value: [
        {
          id: "option1",
          name: "Option1",
          label: "Option 1",
          value: "option1",
        },
      ],
    },
    layoutField,
  ],
  text: [
    {
      name: "Style",
      key: "size",
      type: "select",
      value: TextOptions[0],
      defaultValue: TextOptions[0].value,
      options: TextOptions,
    },
    {
      name: "Text",
      key: "value",
      type: "input",
    },
  ],
  datepicker: [
    nameField,
    labelField,
    requiredField,
    disableField,
    tooltipField,
    placeholderField,
    {
      name: "Default Value",
      key: "value",
      type: "datepicker",
      errormsg: errorType.invalidDate,
      hasToggle: true,
      placeholder: "set current date",
    },
    {
      name: "Minimum Date",
      key: "min",
      type: "datepicker",
      errormsg: errorType.invalidDate,
      hasToggle: true,
    },
    {
      name: "Maximum Date",
      key: "max",
      type: "datepicker",
      errormsg: errorType.invalidDate,
      hasToggle: true,
    },
    layoutField,
  ],
  textarea: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    placeholderField,
    {
      name: "Default Value",
      key: "value",
      type: "input",
      hasToggle: true,
    },
    {
      name: "Minimum Length",
      key: "minLength",
      type: "input",
      error: errorType.invalidMinLength,
      hasToggle: true,
      subType: "number",
    },
    {
      name: "Maximum Length",
      key: "maxLength",
      type: "input",
      error: errorType.invalidMaxLength,
      hasToggle: true,
      subType: "number",
    },
    layoutField,
  ],
  file: [
    nameField,
    labelField,
    requiredField,
    disableField,
    updatableField,
    tooltipField,
    {
      name: "File Type",
      key: "fileTypes",
      type: "multiSelect",
      options: FileOptions,
      hasToggle: true,
    },
    {
      name: "File Size",
      key: "size",
      type: "input",
      placeholder: "Enter maximum file size in MB",
      hasToggle: true,
      subType: "number",
    },
    layoutField,
  ],
  image: [
    {
      name: "Width(px)",
      key: "previewWidth",
      type: "input",
      subType: "number",
    },
  ],
  photoCapture: [nameField, requiredField, layoutField],
  button: [
    {
      name: "Label",
      key: "label",
      type: "input",
      error: errorType.labelSize,
    },
  ],
  sectionStart: [
    labelField,
    {
      name: "Border",
      key: "border",
      type: "checkbox",
    },
  ],
};

export type SingleBlockType = {
  id: number;
  name: string;
  type: string;
  properties?: any;
  propertiesBox?: PropertyBoxType[];
  validation?: any;
};

export type PropertyBoxType = {
  id?: string;
  name: string;
  key: string;
  type: MenuInputType;
  value?: any;
  isSingle?: boolean;
  error?: string;
  validate?: (args: any) => boolean;
  errormsg?: string;
  isEnabled?: boolean;
  hasToggle?: boolean;
  subType?: string;
};

export type MenuInputType =
  | "input"
  | "inputList"
  | "checkbox"
  | "select"
  | "multiSelect"
  | "text"
  | "datepicker";
