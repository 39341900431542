import { createApi } from "@reduxjs/toolkit/query/react";
import { gql } from "graphql-request";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { get, find } from "lodash";
import { getItem } from "utils/store";
import { FORM_STATUS } from "utils/constant";
import {
  successSaveFormInstanceNotification,
  failSaveFormInstanceNotification,
} from "components/Notifications/ToastNotification";
import  { getSerializedFormData } from "utils";

interface frm_form_field {
  key: String;
  value: String;
}
export const defaultBlock = {
  type: { label: "One", value: "w-full", no_column: 1, category: "LAYOUT" },
  blocks: [null],
};

export const defaultLayout = [
  {
    type: { label: "One", value: "w-full", no_column: 1, category: "TITLE" },
    blocks: [
      {
        properties: {
          label: "Submit",
          name: "button",
        },
        type: "button",
      },
      {
        properties: {
          size: { label: "Heading 1", value: "text-3xl" },
          label: "Add your label",
          value: "Form Title",
        },
        type: "text",
      },
    ],
  },
  { ...defaultBlock },
];

export const rootUrl = process.env.REACT_APP_BASE_URL;

export const updateFormBuilderApi = createApi({
  reducerPath: "form_id",
  baseQuery: graphqlRequestBaseQuery({
    url: `${rootUrl}/v1/graphql`,
    prepareHeaders: (headers) => {
      const { access_token, default_role, tenant_id } =
        getItem("user_details") || {};
      if (access_token && tenant_id)
        headers.set("Authorization", `Bearer ${access_token}`);
      if (default_role) headers.set("x-hasura-role", default_role);
      return headers;
    },
  }),
  tagTypes: [FORM_STATUS.DRAFT, FORM_STATUS.PUBLISHED],

  endpoints: (builder: any) => ({
    exchangeToken: builder.mutation({
      query: ({ token }: { token: string }) => ({
        document: gql`
          mutation ($token: String!) {
            auth_get_exchange_token(token: $token) {
              access_token
              custom_token
            }
          }
        `,
        variables: {
          token,
        },
      }),
    }),
    getFormDraft: builder.query({
      query: ({ id }: any) => ({
        document: gql`
            query MyQuery {
              frm_form_by_pk(id: ${id}) {
                form_versions(where: {status: {_in: [DRAFT,PUBLISHED]}}) {
                  structure
                  status
                }
                id
                name
                type
              }
            }
          `,
      }),
      providesTags: [FORM_STATUS.DRAFT],
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { name, type, form_versions },
        } = response || {};
        // Prioritize draft version, if available, otherwise fallback to published
        const draftOrPublished =
          find(
            form_versions,
            (version: any) => version?.status === FORM_STATUS.DRAFT,
          ) || form_versions?.[0];
        return { name, type, structure: draftOrPublished?.structure };
      },
    }),
    getFormStructure: builder.query({
      query: ({ id }: { id: number }) => ({
        document: gql`
                query {
                  frm_form_by_pk(id: ${id}) {
                    id
                    form_versions(where: {status: {_eq: PUBLISHED}}) {
                      id
                      structure
                      status
                      version
                    }
                    type
                  }
                }
          `,
      }),
      transformResponse: (response: any) => {
        const {
          frm_form_by_pk: { type, form_versions },
        } = response || {};
        return { type, structure: get(form_versions, "0.structure"), form_version_id: get(form_versions, "0.id") };
      },
      providesTags: [FORM_STATUS.PUBLISHED],
    }),
    getSelectFieldTypes: builder.query({
      query: () => ({
        document: gql`
          query {
            frm_select_field_type {
              id
              name
            }
          }
        `,
      }),
    }),
    getSelectFieldData: builder.query({
      query: ({ id, parentId }: { id: number; parentId: number }) => ({
        document: parentId
          ? gql`
              query ($id: Int!, $parentId: Int!) {
                frm_select_field_data(
                  where: {
                    select_field_type_id: { _eq: $id }
                    parent_id: { _eq: $parentId }
                  }
                ) {
                  key
                  value
                  id
                }
              }
            `
          : gql`
              query ($id: Int!) {
                frm_select_field_data(
                  where: { select_field_type_id: { _eq: $id } }
                ) {
                  key
                  value
                  id
                }
              }
            `,
        variables: { id, parentId },
      }),
    }),
    createForm: builder.mutation({
      query: ({ name, type }: { name: string; type: string }) => ({
        document: gql`
          mutation ($name: String!, $type: String!) {
            frm_create_form(name: $name, type: $type) {
              data {
                id
                name
                type
              }
            }
          }
        `,
        variables: {
          name,
          type,
        },
      }),
    }),
    updateForm: builder.mutation({
      query: ({ form_id, structure }: { form_id: string; structure: any }) => {
        return {
          document: gql`
            mutation ($form_id: Int!, $structure: jsonb!) {
              frm_update_form_version(
                form_id: $form_id
                structure: $structure
              ) {
                form_id
                structure
              }
            }
          `,
          variables: {
            form_id,
            structure: structure,
          },
        };
      },
    }),
    publishForm: builder.mutation({
      query: ({ form_id }: { form_id: number }) => ({
        document: gql`
          mutation ($form_id: Int!) {
            frm_publish_form(form_id: $form_id) {
              form_id
              status
              structure
            }
          }
        `,
        variables: {
          form_id,
        },
      }),
      invalidatesTags: [FORM_STATUS.PUBLISHED],
    }),
    saveFormInstance: builder.mutation({
      query: ({
        formFieldsData,
        formId,
      }: {
        formFieldsData: frm_form_field[];
        formId: number;
      }) => ({
        document: gql`
          mutation ($formFieldsData: [frm_form_field!]!, $formId: Int!) {
            frm_form_instance_save(
              form_data: $formFieldsData
              form_id: $formId
            ) {
              id
            }
          }
        `,
        variables: {
          formFieldsData,
          formId,
        },
      }),
      onQueryStarted: async (arg: any, { queryFulfilled }: any) => {
        try {
          await queryFulfilled;
          successSaveFormInstanceNotification();
        } catch (error) {
          failSaveFormInstanceNotification();
        }
      },
    }),
    uploadFile: builder.mutation({
      query: ({
        fileName,
        fileType,
        user_id,
        uploadCallback,
      }: {
        fileName: string;
        fileType: string;
        user_id: number;
        uploadCallback: () => void;
      }) => ({
        document: gql`
          mutation fileUpload(
            $fileName: String!
            $fileType: String!
            $user_id: Int!
          ) {
            file_upload_file(
              extension: $fileType
              file_type_id: 46
              original_name: $fileName
              owner_id: $user_id
            ) {
              id
              presigned_url
            }
          }
        `,
        variables: {
          fileName,
          fileType,
          user_id,
        },
      }),
      async onQueryStarted(arg: any, { queryFulfilled }: any) {
        const { uploadCallback } = arg || {};
        try {
          const data = await queryFulfilled;
          uploadCallback?.(data);
        } catch (error) {
          console.error("Error uploading file:", error);
          uploadCallback?.();
        }
      },
    }),
    fetchCandidateDetails: builder.query({
      query: () => ({
        document: gql`
          query candidateDetails {
            can_candidate {
              first_name
              last_name
              external_id
              email
              middle_name
              phone_number
            }
          }
        `,
      }),
    }),
    getFormInstance: builder.query({
      query: ({ form_version_id }: {form_version_id: number }) => ({
        document: gql`
          query get_frm_form_instance($form_version_id: Int!){
            frm_form_instance(
              where: {
                form_version_id: { _eq: $form_version_id}
              },
              order_by: { id: desc }
            ) {
              id
              form_version_id
              form_data
            }
          }
        `,
        variables: {
          form_version_id,
        }
      }),
      transformResponse: (response: any) => {
        const {
          frm_form_instance,
        } = response || {};
        const form_data = get(frm_form_instance, "0.form_data");
        return getSerializedFormData(form_data);
      },
    }),
    getFileDetails: builder.query({
      query: ({ file_id }: { file_id: number }) => ({
        document: gql`
           query get_file_download_file($file_id: Int!){
            file_download_file(id: $file_id){
              id
              original_filename
              resource_url
              key
              expires_in
            }
           }
        `,
        variables: {
          file_id,
        }
      }),
      transformResponse: (response: any) => {
        const {
          file_download_file,
        } = response || {};
        return file_download_file;
      },
    }),
  }),
});

export const {
  useExchangeTokenMutation,
  useGetFormDraftQuery,
  useGetFormStructureQuery,
  useGetSelectFieldTypesQuery,
  useGetSelectFieldDataQuery,
  useCreateFormMutation,
  useUpdateFormMutation,
  usePublishFormMutation,
  useSaveFormInstanceMutation,
  useUploadFileMutation,
  useFetchCandidateDetailsQuery,
  useGetFormInstanceQuery,
  useGetFileDetailsQuery,
} = updateFormBuilderApi;
