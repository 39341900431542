import { configureStore } from "@reduxjs/toolkit";
import { updateFormBuilderApi } from "store/services/updateFormBuilder";
import rootReducer from "store/reducers";

export const store = configureStore({
  reducer: {
    form: rootReducer,
    [updateFormBuilderApi.reducerPath]: updateFormBuilderApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(updateFormBuilderApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
