import React, { ReactNode, useState, useRef, useLayoutEffect } from "react";
import { get } from "lodash";
import { useExchangeTokenMutation } from "store/services/updateFormBuilder";
import { processToken } from "utils";
import Loader from "components/Loader";

const Auth: React.FC<{ children: ReactNode }> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("accessToken") || "";
  const [exchangeToken] = useExchangeTokenMutation();

  const [isAuthComplete, setIsAuthComplete] = useState(false);
  const hasCalledExchangeToken = useRef(false);

  useLayoutEffect(() => {
    if (token && !isAuthComplete && !hasCalledExchangeToken.current) {
      hasCalledExchangeToken.current = true;
      const { tenant_id } = processToken(token) || {};
      if (!tenant_id) {
        exchangeToken({ token }).then((res) => {
          const accessToken = get(
            res,
            "data.auth_get_exchange_token.access_token",
          );
          if (accessToken) processToken(accessToken);
          setIsAuthComplete(true);
        });
      } else {
        setIsAuthComplete(true);
      }
    }
  }, []);

  return isAuthComplete ? (
    <div>{children}</div>
  ) : (
    <div className="min-h-screen flex items-center justify-center">
      <Loader />
    </div>
  );
};

export default Auth;
